import type { HTMLProps } from 'react';
import React from 'react';

interface SearchIconProps extends HTMLProps<HTMLDivElement> {}
const SearchIcon: React.FC<SearchIconProps> = ({ className, ...rest }) => {
  return (
    <div className={`${className}`} {...rest}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M19.8422 19.0115L14.8087 13.9779C16.2441 12.3272 16.9825 10.1837 16.8682 7.99915C16.754 5.81462 15.7961 3.75979 14.1963 2.26779C12.5966 0.775788 10.4801 -0.0368117 8.29291 0.00128124C6.10573 0.0393742 4.01879 0.925184 2.47198 2.47198C0.925184 4.01879 0.0393742 6.10573 0.00128124 8.29291C-0.0368117 10.4801 0.775788 12.5966 2.26779 14.1963C3.75979 15.7961 5.81462 16.754 7.99915 16.8682C10.1837 16.9825 12.3272 16.2441 13.9779 14.8087L19.0115 19.8422C19.1229 19.9461 19.2703 20.0026 19.4226 19.9999C19.5748 19.9972 19.7201 19.9355 19.8278 19.8278C19.9355 19.7201 19.9972 19.5748 19.9999 19.4226C20.0026 19.2703 19.9461 19.1229 19.8422 19.0115ZM1.20495 8.45453C1.20495 7.0207 1.63013 5.61907 2.42672 4.42688C3.22332 3.23469 4.35555 2.3055 5.68023 1.75679C7.00492 1.20809 8.46257 1.06452 9.86885 1.34425C11.2751 1.62398 12.5669 2.31443 13.5808 3.3283C14.5946 4.34217 15.2851 5.63392 15.5648 7.04021C15.8445 8.44649 15.701 9.90413 15.1523 11.2288C14.6036 12.5535 13.6744 13.6857 12.4822 14.4823C11.29 15.2789 9.88836 15.7041 8.45453 15.7041C6.53254 15.7018 4.68993 14.9372 3.33088 13.5782C1.97183 12.2191 1.20728 10.3765 1.20495 8.45453Z"
          fill="#020202"
        />
      </svg>
    </div>
  );
};

export default SearchIcon;
