import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/main/advantages/Advantages.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/main/counters/Counters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/main/hero/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/main/invitation/Invitation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/main/our_history/OurHistory.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/main/partners/Partners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/main/posts/Posts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/modals/cookies/CookiesModal.tsx");
